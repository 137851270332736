@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


*{font-family: "Syne", sans-serif;
  
  

}
body{
  overflow-x: hidden !important;

  background-color: black !important;
}


a{
  text-decoration: none !important;
}


.gradient-text {
  background: linear-gradient(90deg, #F9F8A5 0%, #DDA80E 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}



.border-gradient {
  border-width: 2.14px;
  border-image-source: linear-gradient(86.91deg, #77D9FF 8.72%, #3091EE 94.71%);
  border-image-slice: 1;
  border-radius:1%;
}

.bg-hero-cut {
  background-size: cover;
  background-position: center;
  

}



